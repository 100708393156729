<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card title="Wellstar Data" text="Show wellstar invoice and quote data">
                    <template :slot="'header_actions'">
                        <v-btn small fab rounded
                               v-tooltip="'New User'" color="white"
                               @click="set_edit_user({})">
                            <v-icon color="primary">
                                mdi-account-multiple-plus
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-data-table :headers="headers"
                                  :items="users"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>

                        <template v-slot:item.specialty="{ item }">
                            <div v-for="sp in item.department_categories">
                                {{ sp.name }}
                            </div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-btn small fab text
                                   @click="set_state(2, item)"
                                   v-if="item.profile.state === 1"
                                   uk-tooltip="title: Disable User">
                                <v-icon>mdi-account-minus</v-icon>
                            </v-btn>

                            <v-btn small fab text
                                   @click="set_state(1, item)"
                                   v-if="item.profile.state === 2"
                                   uk-tooltip="title: Enable User">
                                <v-icon>mdi-account-check</v-icon>
                            </v-btn>

                            <v-btn small fab text
                                   @click="set_edit_user(item)"
                                   uk-tooltip="title: Edit User">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>


                </material-card>
            </v-flex>
            <v-flex
                    md12
            >
            </v-flex>
        </v-layout>
        <v-dialog v-model="edit_user_dialog" width="600" :persistent="true">
            <v-card class="pa-7">
                <v-card-title>
                    Edit User
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                        v-model="user.first_name"
                                        :counter="200"
                                        label="First Name"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                        v-model="user.last_name"
                                        :counter="200"
                                        label="Last Name"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                        v-model="user.username"
                                        :counter="200"
                                        label="Email"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" v-if="user.id === 0">
                                <v-text-field
                                        v-model="user.password"
                                        :counter="50"
                                        label="Password"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                        v-model="user.profile.phone"
                                        :counter="100"
                                        label="Phone N"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                        v-model="user.profile.phone_extension"
                                        :counter="20"
                                        label="Phone Extension"

                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12">
                                <v-select
                                        v-model="user.profile.specialty_tags"
                                        :items="department_categories"
                                        :multiple="true"
                                        :menu-props="{'closeOnContentClick':true}"
                                        item-value="id"
                                        item-text="name"
                                        label="Specialty"
                                ></v-select>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" lg="12">
                                <v-select
                                        v-model="user.profile.department_id"
                                        :items="departments"
                                        :multiple="false"
                                        :menu-props="{'closeOnContentClick':true}"
                                        item-value="id"
                                        item-text="name"
                                        label="Department"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue-grey darken-4"
                            text
                            outlined
                            @click="edit_user_dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="blue-grey darken-4"
                            text
                            outlined
                            @click="save_user(user)"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        name: "Users",
        data: () => ({
            title: "Users",
            users: [],
            headers: [
                {
                    text: 'ID',
                    align: 'center',
                    value: 'id',
                },
                {
                    text: 'Username',
                    align: 'center',
                    value: 'username',
                },
                {
                    text: 'Specialty',
                    align: 'center',
                    value: 'specialty',
                },
                {
                    text: 'Actions',
                    align: 'center',
                    value: 'actions',
                    custom: true
                }
            ],
            show_actions: false,
            edit_user_dialog: false,
            department_categories: [],
            default_user: {
                id: 0,
                first_name: '',
                last_name: '',
                password: '',
                email: '',
                username: '',
                profile: {
                    id: 0,
                    address: '',
                    region: '',
                    state: 1,
                    zip_code: '',
                    country_id: 44,
                    phone: '',
                    phone_extension: '',
                    mobile: '',
                    specialty_tags: []
                }
            },
            user: {
                id: 0,
                first_name: '',
                last_name: '',
                password: '',
                email: '',
                username: '',
                profile: {
                    id: 0,
                    state: 1,
                    country_id: 44,
                    phone: '',
                    phone_extension: '',
                    specialty_tags: []
                }
            },
            departments: [],
            loading:true,
        }),
        created() {
            this.load_dep_cats();
        },
        methods: {
            set_state(state, user) {
                this.$http.patch(
                    this.$http.defaults.baseURL + '/partners/user-profiles/' + user.profile.id + '/', {
                        state: state
                    }).then((response) => {
                    this.$notify({
                        title: 'Success',
                        text: 'User State Changed',
                        group: 'notifications',
                        position: 'top right'
                    });
                    user.profile.state = state;
                    this.$forceUpdate();
                }).catch((error) => {
                    console.log("Error");
                    console.log(error);
                });
            },
            load_dep_cats() {
                this.$hget(
                    this.$http.defaults.baseURL + '/admins/department-categories-simple/').then((response) => {
                    this.department_categories = response.data;
                    this.load_departments();
                });
            },
            load_departments()
            {
                this.$hget(
                    this.$http.defaults.baseURL + '/partners/departments/').then((response) => {
                    this.load_users();
                    this.departments = response.data;
                })

            },
            load_users() {
                this.$hget(this.$http.defaults.baseURL + '/partners/users/')
                    .then((response) => {
                        let users = [];
                        let self = this;
                        this.loading = false;
                        response.data.results.forEach(function (user) {
                            let dep_categories = [];

                            if (!user.profile.specialty_tags || user.profile.specialty_tags === "") {
                                user.profile.specialty_tags = [];
                            } else {
                                user.profile.specialty_tags = JSON.parse(user.profile.specialty_tags);
                                user.profile.specialty_tags.forEach(function (dc) {
                                    console.log(dc);
                                    let existing = self.department_categories.find(o => o.id === dc);
                                    if (typeof existing !== 'undefined') {
                                        dep_categories.push(existing);
                                    }
                                });
                            }
                            user.department_categories = dep_categories;
                            users.push(user);
                        });
                        this.users = users;
                    });
            },
            set_edit_user(user) {
                if (typeof user.id === 'undefined') {
                    this.user = this.default_user;
                } else {
                    this.user = user;
                }
                this.edit_user_dialog = true;
            },
            save_user(user) {
                if (user.id === 0) {
                    user.email = user.username;

                    this.$hpost(
                        this.$http.defaults.baseURL + '/partners/users/', user).then((response) => {
                        user.id = response.data.id;
                        this.$notify({
                            title: ':Success:',
                            text: 'Created User Successfully',
                            group: 'notifications',
                            position: 'top right'
                        });

                        this.edit_user_dialog = false;
                        this.users.push(user);
                    });
                } else {
                    // update
                    console.log(user);
                    this.$http.patch(
                        this.$http.defaults.baseURL + '/partners/users/' + user.id + '/', user).then((response) => {
                        this.$notify({
                            title: ':Success:',
                            text: 'Created User Successfully',
                            group: 'notifications',
                            position: 'top right'
                        });

                        this.edit_user_dialog = false;

                    })
                        .catch((error) => {
                            this.notification = true;
                            this.notification_message = 'Error: Please contact administrator';
                            console.log("Error");
                            console.log(error);
                        });
                }
            },
        }
    }
</script>

<style scoped>

</style>
